<template>
  <div class="login-container">
    <img
      class="h-full"
      id="illustration"
      @load="getImageInfo"
      style="position: absolute; opacity: 0; z-index: -1"
      src="../../assets/illustration.png"
    />
    <div class="login-form">
      <div class="login-detail">
        <div class="verify-wrapper">
          <div
            class="v-code-wrapper verify-container mt-4 flex flex-col items-center"
          >
            <div>
              <img
                src="../../assets/login_logo.png"
                class="mx-auto"
                style="
                  width: 138px;
                  height: auto;
                  object-fit: cover;
                  margin-bottom: 25px;
                "
              />
              <p class="title text-center">Verify Email</p>
            </div>
            <p class="sub-title text-center">OTP sent to your email</p>
            <div class="code-input-box my-8">
              <div class="v-code">
                <input
                  ref="vcode"
                  id="vcode"
                  maxlength="6"
                  v-model="vCode"
                  @focus="focused = true"
                  @blur="focused = false"
                  @keydown="forbidMoveCursor($event)"
                  :autofocus="false"
                />
                <label
                  for="vcode"
                  class="line"
                  v-for="(item, index) in codeLength"
                  :key="index"
                  :class="{ animated: focused && cursorIndex === index }"
                  v-text="codeArr[index]"
                >
                </label>
              </div>
            </div>
            <div class="login-btn" @click="handleVerify">VERIFY</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="swiper-container"
      :style="{ width: swiperContainerWidth + 'px' }"
    >
      <img
        class="h-full"
        style="position: absolute"
        src="../../assets/illustration.png"
      />
      <swiper :options="swiperOptions">
        <swiper-slide
          v-for="(item, index) in 2"
          :key="index"
          class="slide-item"
        >
          <!-- <img class="h-full" id="illustration" src="../../assets/illustration.png" /> -->
          <img
            class="guide-image absolute"
            :style="{ width: guideWidth + 'px' }"
            v-if="index == 0"
            src="../../assets/guide.png"
          />
          <img
            class="guide-image2 absolute"
            :style="{ width: guide2Width + 'px' }"
            v-else
            src="../../assets/guide2.png"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import "../../assets/common/common.css";
import "../../assets/common/font.css";
import { loginByEmail, createLoginPinCode } from "../../api/reff";
import { setUserEmail, setUserId, setUserType } from "../../utils/store";

export default {
  name: "InputPwd",
  data() {
    return {
      swiperOptions: {
        // Swiper options here
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        }, // 自动轮播
        speed: 3000, // 轮播速度
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      },
      vCode: "",
      codeLength: 6,
      focused: false,
      isSendCode: false,
      isMobile: false,
      email: "",
      pwd: "",
      guideWidth: 0,
      guide2Width: 0,
      swiperContainerWidth: 0,
    };
  },

  computed: {
    codeArr() {
      return this.vCode.split("");
    },
    cursorIndex() {
      return this.vCode.length;
    },
  },
  watch: {
    vCode(newVal, oldVal) {
      const reg = /^[A-Za-z0-9]+$/;
      if (reg.test(newVal)) {
        this.vCode = newVal;
      } else {
        this.vCode = newVal.replace(/[^\w]/g, "");
      }
      if (newVal.length === 6) {
        this.handleVerify();
      }
    },
  },

  mounted() {
    this.isMobile = this.$isMobile;

    let url = window.location.href;
    let getInfo = url.split("?")[1];
    let getParams = new URLSearchParams("?" + getInfo);
    this.email = decodeURIComponent(getParams.get("email"));
    this.pwd = decodeURIComponent(getParams.get("pwd"));

    if (this.email == undefined || this.email == null || this.email == "") {
      this.email = this.$route.query.email;
    }
    if (
      this.pwd != undefined &&
      this.pwd != null &&
      this.pwd != "" &&
      this.pwd.length == 6
    ) {
      this.vCode = this.pwd;
    }

    this.$refs.vcode.focus()
  },
  methods: {
    getImageInfo() {
      const width = document.getElementById("illustration").offsetWidth;
      this.swiperContainerWidth = width;
      this.guideWidth = width * 0.62;
      this.guide2Width = width * 0.86;
    },

    forbidMoveCursor(event) {
      if (event.keyCode >= 35 && event.keyCode <= 40) {
        event.preventDefault();
      }
    },
    async handleVerify() {
      if (!this.vCode) {
        this.$message.warning("Please input OTP code");
        return;
      }
      try {
        const res = await loginByEmail(this.email, this.vCode, "网页", "user");
        if (res.data.status === "success") {
          console.log(res);
          this.$message({
            message: "Login successfully.",
            type: "success",
            duration: 1500,
          });
          let user_id = res.data.data._id.$id;
          setUserId(user_id);
          setUserEmail(this.email);
          setUserType("user");
          this.$UserData.user_email = this.email;
          this.$message.success("Login Success");
          this.$router.replace("/");
          this.$router.push("/");
        } else {
          this.$message({
            message: "OTP Incorrect",
            type: "error",
            duration: 1500,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    resend() {
      createLoginPinCode(this.email, "user").then((res) => {
        this.$message.success("OTP sent,Please check your email");
      });
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.href.substr(1).match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.login-detail {
  width: 480px;
  display: flex;
  flex-direction: column;
}
.login-form {
  flex: 1;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  .title {
    font-size: 32px;
    color: #024b53;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 16px;
  }
  .sub-title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.3);
    line-height: 1.1;
  }
  .login-btn {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    background: #e2ff54;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 24px;
    margin-top: 40px;
  }
}
.swiper-container {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .slide-item {
    height: 100%;
    position: relative;

    .guide-image {
      position: absolute;
      width: 300px;
      height: auto;
      top: 4vh;
      left: 50%;
      transform: translateX(-50%);
    }

    .guide-image2 {
      position: absolute;
      width: 420px;
      height: auto;
      top: 9vh;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.swiper-container::-webkit-scrollbar {
  display: none;
}
.v-code-wrapper {
  background-color: #fff;
  text-align: center;
}

.v-code-wrapper .code-input-box {
  color: #2e2f33;
  font-size: 16px;
}

.v-code-wrapper .code-input-box .v-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.v-code-wrapper .code-input-box .v-code input {
  position: absolute;
  top: -100%;
  left: -666666px;
  opacity: 0;
}

.v-code-wrapper .code-input-box .v-code .line {
  position: relative;
  margin-right: 10px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 50px;
  color: #2e2f33;
  border-bottom: 1px solid #2e2f33;
  cursor: text;
}

.v-code-wrapper .code-input-box .v-code .line::after {
  display: block;
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  bottom: 0;
  height: 1px;
  transform: scaleY(0.5);
  transform-origin: 0 100%;
}

.v-code-wrapper .code-input-box .v-code .line.animated::before {
  display: block;
  position: absolute;
  left: 50%;
  top: 35%;
  width: 1px;
  height: 45%;
  content: "";
  background-color: #2e2f33;
  animation-name: coruscate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@keyframes coruscate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
